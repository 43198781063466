.bundle-options {
	clear:both;
	list-style:none;
	margin:1em (-$grid-gutter-width)/2 0.5em (-$grid-gutter-width)/2;
	padding:0;
	border-bottom:1px solid $btn-primary-border;
	@include clearfix;
	> li {
		display:block;
		margin:0;

		&.auto {
			display: none;
		}
	}
	+ h2 {
		margin-top:1.5em;
	}
	@media (min-width: $screen-xm-min) {
		border:none;
		margin:0;
		> li {
			margin:1.625em 0;
		}
	}
    input[readonly],
    input[readonly]+label{
      pointer-events: none;
    }
}

.bundle-option {
	background:#fff;
	color:$text-color;
	border-top:1px solid $btn-primary-border;
	display:table;
	font-weight:normal;
	min-height:4.635em;
	position:relative;
	width:100%;
	margin:0;
	padding:0.5em $grid-gutter-width/2 0.25em 3em;
	@extend .icon-plus;
	transition: border-color .25s ease, color .25s ease;
	&:before, &:after {
		background:$btn-primary-border;
		border-radius:1em;
		color:#fff;
		font-family: 'icomoon';
		height:2em;
		left:0.25em;
		line-height:2em;
		margin:-1em 0 0 0;
		position:absolute;
		text-align:center;
		top:50%;
		width:2em;
		transition: background-color .25s ease;
	}
	&:after {
		display:none;
	}
	> * {
		display:block;
		margin:0;
		vertical-align:middle;
	}
	h3 {
		white-space: nowrap;
		font-weight:bold;
		margin-bottom:0.5em;
		~ * {
			background:#fff;
		}
		+ * {
			text-align:left;
		}
		@media (min-width: $screen-xm-min) {
			text-align:center;
			width:170px;
		}
	}
	strong {
		font-size:1.5em;
		font-weight:normal;
		text-align:right;
		s {
			color:#c9c9c9;
			font-size:0.75em;
		}
		@media (min-width: $screen-xm-min) {
			* {
				display:block;
				white-space: nowrap;
			}
		}
		@media (min-width: $screen-md-min) {
			white-space: nowrap;
			* {
				display:inline-block;
				+ * {
					margin-left:1em;
				}
			}
		}
	}
	&.active {
		&:before {
			background:#60a926;
		}
	}
	&.remove {
		@extend .icon-minus;
		&:before {
			background:#cd262a;
		}
	}
	&.enabled {
		cursor:pointer;
		input[type=checkbox]:focus:not(:disabled) + & {
			@extend .bundle-option.active;
		}
		input[type=checkbox]:not(:disabled) + & {
			&:hover, &:active, &:focus {
				@extend .bundle-option.active;
			}
		}
	}
	&.included {
		@extend .icon-check;
		input[type=checkbox]:focus:not(:disabled) + & {
			@extend .bundle-option.remove;
		}
		input[type=checkbox]:not(:disabled) + & {
			&:hover, &:active, &:focus {
				@extend .bundle-option.remove;
			}
		}
		input[type=checkbox]:not(:checked) + & {
			@extend .bundle-option.remove;
		}
	}
	.touch & {
		&.included:before {
			border-radius:0 0 0 1em;
			margin:0;
			left:auto;
			top:0;
			right:0;
		}
	}
	&.included.enabled:after {
		.touch input[type=checkbox]:not(:disabled) + & {
			display:block;
			@extend .icon-minus:before;
		}
	}
	.decimals {
		display:inline-block;
		text-align:left;
		width:1.5em;
	}
	.loading & {
		&, h3 {
			border-color:$btn-primary-border !important;
			color:$btn-link-disabled-color !important;
		}
		&:before {
			background-color:$btn-primary-border !important;
		}
	}
	@media (min-width: $screen-xm-min) {
		background:$btn-primary-bg;
		border:3px solid $btn-primary-border;
		padding:0;
		&:before {
			left:-1em;
		}
		> * {
			padding:0.5em 1.5em;
			display:table-cell;
		}
		h3 {
			padding:0.444em 1.333em;
			font-weight:normal;
			margin:0;
		}
		strong {
			padding:0.25em 1em 0.25em 0;
		}
		&.active {
			border-color:#60a926;	
		}
		&.remove {
			border-color:#cd262a;
		}
	}
}

.bundle-options-footer {
	text-align:right;
	margin-bottom:1em;
	@media (min-width: $screen-md-min) {
		margin-bottom:2em;
	}
}

.bundles-costsummary {
	color:$text-color;
	display:block;
	vertical-align:top;
	margin:0 0 1em 0;
	* {
		transition: color .25s ease;
	}
	* {
		text-align: right;
		vertical-align: text-bottom;
	}
	td {
		font-size:1.5em;
	}
	th {
		font-size:0.875em;
		font-weight:normal;
		padding-right:26px;
	}
	tfoot tr:first-child {
		td, th {
			padding-top:0.5em;
		}
	}
	.decimals {
		text-align: left;
		width:1.5em;
	}
	.decimals {
		display:inline-block;
	}
	.promo {
		color:#1599fe;
	}
	tfoot .sub td {
		font-size:0.875em;
		font-style:italic;
	}
	.loading & {
		* {
			border-color:$btn-primary-border !important;
			color:$btn-link-disabled-color !important;
		}
	}
	@media (min-width: $screen-md-min) {
		margin:0 26px 0 0;
		display:inline-block;
	}
}