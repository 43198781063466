// Centered container element
@mixin container-fixed() {
	margin-right: auto;
	margin-left: auto;
	@include clearfix();
}

@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
	padding: ($padding-vertical/$font-size)+em ($padding-horizontal/$font-size)+em;
	font-size: ($font-size/$font-size-base)+em;
	line-height: $line-height;
	border-radius: 0;
}

// Form control sizing
//
// Relative text size, padding, and border-radii changes for form controls. For
// horizontal sizing, wrap controls in the predefined grid classes. `<select>`
// element gets special love because it's special, and that's a fact!
// [converter] $parent hack
@mixin input-size($parent, $input-height, $padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  #{$parent} {
    height: ($input-height/$font-size)+em;
    padding: ($padding-vertical/$font-size)+em ($padding-horizontal/$font-size)+em;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
  }

  select#{$parent} {
    height: ($input-height/$font-size)+em;
    line-height: $input-height;
  }

  textarea#{$parent},
  select[multiple]#{$parent} {
    height: auto;
  }
}

// Button variants
// -------------------------
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus,
  &:active,
  &.active {
    color: $color;
    background-color: darken($background, 8%);
        border-color: darken($border, 12%);
    outline:none;
  }
  .open & { &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 8%);
        border-color: darken($border, 12%);
  } }
  &:active,
  &.active {
    background-image: none;
  }
  .open & { &.dropdown-toggle {
    background-image: none;
  } }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $background;
          border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}


// Alerts
// -------------------------
@mixin alert-variant($background, $border, $text-color) {
	background-color: $background;
	color: $text-color;

	hr {
		border-top-color: darken($border, 5%);
	}
	.alert-link {
		color: darken($text-color, 10%);
	}
}

@mixin clip-path($clip) {
	-webkit-clip-path: $clip;
	-moz-clip-path: $clip;
	-ms-clip-path: $clip;
	clip-path: $clip;
}

// Forms
// -------------------------
@mixin form-control-focus($color: $input-border-focus) {
  $color-rgba: rgba(red($color), green($color), blue($color), .6);
  &:focus {
    border-color: $color;
    outline: 0;
  }
}


// Grid System
// -------------------------
// Generate the small columns
@mixin make-xm-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-xm-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-xm-column-offset($columns) {
  @media (min-width: $screen-xm-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xm-column-push($columns) {
  @media (min-width: $screen-xm-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-xm-column-pull($columns) {
  @media (min-width: $screen-xm-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Tiles
// -------------------------
@mixin tile-variant($class, $background, $color, $wellcolor) {
	#{$class}, a#{$class} {
		background-color:$background;
		color:$color;
	}
	#{$class} {
		.btn-default {
			@include button-variant($btn-default-color, darken($background, 3.5%), darken($background, 7%));
		}
		.titlebar {
			background-color:darken($background, 3.5%);
		}
		[data-dropzone] & {
			.number:after {
				color:$color;
			}
		}
    .well {
      .btn-default {
        @include button-variant($btn-default-color, $background, darken($background, 3.5%));
      }
      color:$wellcolor;
    }
	}
}


// Extends for backgrounds with a hexagon shape

%bg-hexagon {
	background-image: url('public/img/svg/hexagon.svg');
	background-color: transparent;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

%bg-hexagon-info { background-image: url('public/img/svg/hexagon-info.svg'); }
%bg-hexagon-success { background-image: url('public/img/svg/hexagon-success.svg'); }
%bg-hexagon-warning { background-image: url('public/img/svg/hexagon-warning.svg'); }
%bg-hexagon-danger { background-image: url('public/img/svg/hexagon-danger.svg'); }
