.tileselect {
	position:absolute;
	@include clearfix;
	margin:0.75em 0 0 -110px;
	width:220px;
	display:none;
	z-index:1000;
	&.show {
		display:block;
	}
	&:before {
		content: '';
		position: absolute;
		bottom: 100%;
		left: 50%;
		border: 0.5em solid transparent;
		border-top: none;
		border-bottom: 0.75em solid #e6e6e6;
		margin-left: -0.5em;
	}
	ul {
		background:#ffffff;
		border-top:1px solid #e6e6e6;
		border-left:1px solid #e6e6e6;
		box-shadow: rgba(0,0,0,0.05) 0 0.375em 0 0.25em, #e6e6e6 -1px -1px 0 0 inset;
		margin:0;
		padding:0;
		display:block;
		position:relative;
		list-style:none;
		@include clearfix;
	}
	li {
		padding:0;
		float:left;
		font-size:2.5em;
		width:73px;
		height:73px;
		position:relative;
	}
	a {
		background:#fff;
		border-right:1px solid #e6e6e6;
		border-bottom:1px solid #e6e6e6;
		position:absolute;
		display:block;
		text-indent:-9999px;
		overflow:hidden;
		text-align:center;
		color:#d2d2d2;
		cursor:pointer;
		top:0;
		right:0;
		bottom:0;
		left:0;
		&:hover, &:focus, &.selected {
			background:#e7e7e7;
			color:#a5a5a5;
			outline:none;
		}
		&:before {
			position:absolute;
			top:50%;
			left:50%;
			margin:-0.5em 0 0 -0.5em;
			text-indent:0;
		}
	}
	@media (max-width: $screen-xs-max) {
		top:auto!important;
		left:0!important;
		bottom:0!important;
		right:0;
		position:fixed;
		margin:0;
		width:100%;
		height:100%;
		transform: perspective(2000);
		z-index:1000;
		&:before {
			display:none;
		}
		ul {
			width:auto;
			display:block;
			position:absolute!important;
			border-left:none;
			left:0;
			right:0;
			bottom:0;
			opacity:0;
			transition: all 0.5s ease;
			transform-style: preserve-3d;
		}
		li {
			width:25%;
			padding-top:25%;
		}
		&.show {
			animation: bgoverlayin 0.5s ease forwards;
			ul {
				animation: tilein 0.5s ease forwards;
			}
		}
	}
}