header.primary {
	display:table;
    :hover, :focus, :active {
        text-decoration:none;
        outline:none;
    }
	div {
		white-space:nowrap;
		display:table-cell;
		padding:1.5em 0 1.5em 1.5em;
		vertical-align:middle;
		& + div {
			text-align:right;
			padding:1.5em 1.5em 1.5em 0.5em;
			.dropdown-menu {
				text-align:left;
			}
		}
		svg, h1 {
			vertical-align:middle;
			display:inline-block;
			position:relative;
		}
	}
	h1 {
		font-size:1.875em;
		margin:0 0 0 0.5em;
		text-transform:uppercase;
//		&:after {
//			content:'B\00e8ta';
//			font-size:0.4em;
//		}
	}
	.personal {
		position:relative;
		line-height:2.375em;
		float:right;
		* {
			vertical-align: bottom;
		}
	}
	.header-navigation {
		display:inline-block;
		position:relative;
	}
	.header-notifications {
		position: absolute;
		left:-30px;
		top:-19px;
		width:38px;
		height:38px;
		[data-notificationcount] {
			font-size:1.125em;
	        line-height:38px;
	        display:block;
	        text-align: center;
		}
	}

	.avatar,
	.show-search {
		@extend %bg-hexagon;

		width:38px;
        line-height:38px;
        text-align:center;
        border:none;
        padding:0;
	}

	.show-search {
		@extend %bg-hexagon-info;
	}

	.avatar,
	[data-notificationcount] {
		vertical-align:bottom;
	}

	[data-notificationcount] {
        transition: all	 0.25s ease;
        &[data-count="0"] {
        	transform: scale(0);
        }
	}
	.header-link {
		span {
			font-size:1.125em;
			line-height:38px;
			color:#000;
			text-decoration:none;
			text-transform:uppercase;
			display:inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width:25vw;
		}
		> * + * {
			margin-left:0.5em;
		}
	}
	.global-search {
		form {
			width:100%;
			background:#f2f2f2;
			position:relative;
			font-size:1.25em;
		}
		[type=search] {
			border:none;
			background:transparent;
			padding:0;
			height:2em;
			width:100%;
			padding:0 2em 0 0.5em;
		}
		button {
			position:absolute;
			line-height:2em;
			height:2em;
			width:2em;
			top:0;
			border:none;
			background:transparent;
			padding:0;
			&:focus, &:active {
				transform: scale(1.1);
			}
		}
		[type=submit] {
			right:0;
		}
		[type=button] {
			left:0;
		}
	}

	.dropdown-menu {
		min-width:100%;
		width:auto;
		white-space:normal;
		position: absolute;

		left: 50%;
		transform: translateX(-50%);

		&:before, &:after {
			content:'';
			position:absolute;
			bottom:100%;
			left:50%;
			border:0.5em solid transparent;
			border-top:none;
			border-bottom:0.75em solid $dropdown-border;
			margin-left:-0.5em;
		}

		&:after {
			border-bottom-color:$dropdown-bg;
			margin-bottom:-1px;
	   }
	}

	@media (max-width: $screen-sm-max) {
		width:100%;
		position:relative;
		div {
			padding:0.5em;
			float:left;
			&+div {
				float:none;
				text-align:right;
				padding:0.5em 0.5em 0.5em 1em;
			}
            svg {
                height:50px;
            }
		}
		h1 {
			display:none;
		}
		.header-notifications {
			position: static;
			display: inline-block;
		}
		.personal {
			position:static;
		}
		.open {
			position:static;
			.dropdown-menu {
				top: 100%;
				left: 0;
				right: 0;
				margin: 0;
				transform: none;

				&:before, &:after {
					display:none;
				}
			}
		}
	}
    @media (max-width: $screen-xs-max) {
        div svg {
            height:38px;
            margin-top:5px;
        }
		.global-search {
			position:absolute;
			bottom:100%;
			left:0;
			right:0;
			padding:0.5em $grid-gutter-width/4;
			height:100%;
			[type=search] {
				padding-left:2em;
			}
		}
		> * {
			transition: all 0.25s ease;
		}
		&.show-search {
			overflow:hidden;
			> * {
				transform: translate3d(0, 100%, 0);
			}
		}
    }
}
