.tile-receipts {
	@include clearfix;
	.summary, .receipt-tiles {
		clear:both;
		padding:0;
		list-style:none;
		margin:0 (-$grid-gutter-width/4);
		//overflow:hidden;
		> li {
			padding:$grid-gutter-width/4;
		}
	}
    .no-results {
       text-align:center;
    }
	.summary {
		li > * {
			background:rgba(0, 0, 0, 0.05);
			display:block;
			text-align:right;
			padding:0.5em 1em;
			> * {
				font-size:1.125em;
				white-space:nowrap;
			}
			span {
				float:left;
				text-align:left;
				text-transform:uppercase;
			}
		}
	}
	.receipt-tiles {
		transition: opacity 0.25s ease;
		opacity:1;
		@include clearfix;
		min-height:18.125em;
		&.loading {
			.link {
				animation: loading 1s ease infinite;
				text-decoration:none;
				cursor:default;
			}
		}
		&.init {
			opacity:0;
		}
	}
	.content {
		padding-top:$grid-gutter-width/4;
		padding-bottom:$grid-gutter-width/4;
		&.widepadding {
			padding:$grid-gutter-width/2 ($grid-gutter-width)-1;
			.summary, .receipt-tiles {
				margin:0 (-$grid-gutter-width/2);
				> li {
					padding:$grid-gutter-width/2;
				}
			}
			.summary {
				> li * > * {
					font-size:1em;
				}
			}
		}
	}
	.link {
		background:rgba(0,0,0,0.05);
		text-align:center;
		display:table;
		width:100%;
		text-transform:uppercase;
		transition: opacity 0.25s ease;
		> * {
			display:table-cell;
			height:7.8em;
			vertical-align:middle;
		}
	}
	.receipt {
		display:block;
		background:#fff;
		text-align:right;
		color:#000;
		position:relative;
		text-decoration:none;
		height:7.8em;
		padding:1em;
		transition: opacity 0.25s ease;

		h1 {
			margin:0 0 0 2.5em;
			color:#999999;
			font-size:1.125em;
            font-weight:normal;
            line-height:1.2em;
            white-space:nowrap;
            overflow:hidden;
            text-overflow: ellipsis;
		}
		.total {
            display:block;
			font-weight:bold;
			font-size:1.625em;
		}
		.tax {
            display:block;
			font-size:1.25em;
		}
		&:before {
			content:'';
			position:absolute;
			left:100%;
			bottom:0;
			width:0;
			height:0;
			border:0 solid transparent;
			border-width:6em 0.75em 0 0;
			border-top-color:#fff;
			opacity:0.5;
			transition: border-width 0.25s ease;
			z-index:2;
		}
        .date {
            display:block;
        }
		.category {
			position:absolute;
			top:0.375em;
			left:0.375em;
			margin:0;
			color:#a5a5a5;
			font-size:2.5em;
            &.icon-overig, &.unknown {
                width:1em;
                height:1em;
                border:2px dashed #e6e6e6;
            }
		}
		&:not(.loading) [data-tileselect]:hover {
			color:#000;
			border-color:#000;
		}
		&.loading {
			animation: loading 1s ease infinite;
			text-decoration:none;
			cursor:default;
			&:before {
				animation: loadingflap 1s ease infinite;
			}
		}
		.tag {
			position:absolute;
			left:0;
			bottom:0;
		}
        .taginfo {
            text-align:left;
            position:absolute;
            left:0;
            top:100%;
            right:0;
            width:auto;
        }
	}
	a.receipt {
		&:hover, &:focus, &:active {
			outline:none;
			&:before {
				outline:none;
				border-width:5em 1em 0 0;
			}
		}
	}
	.receipt-alerts {
		margin:0;
	}
	@media (max-width: $screen-xm-max) {
		.content.widepadding {
			padding:$grid-gutter-width/4 ($grid-gutter-width/2)-1;
			.summary, .receipt-tiles {
				margin:0 (-$grid-gutter-width/4);
				> li {
					padding:$grid-gutter-width/4;
				}
			}
		}
	}
}
