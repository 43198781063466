.alerts {
	list-style:none;
	padding:0;
	margin-top:0;
	margin-bottom:0;
	z-index:10;
	li {
		margin:$grid-gutter-width/4 0;
	}
}

.alert {
	max-height:10em;
	border:none;
	overflow:hidden;
	transition: all 1s ease;
	&.collapsed {
		margin-top:0;
		margin-bottom:0;
		padding-top:0;
		padding-bottom:0;
		max-height:0;
	}
	.close {
		opacity:1;
		@media (max-width: $screen-xs-max) {
			font-size: ($font-size-base * 2);
		}
		@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
			font-size: ($font-size-base * 1.75);
		}
	}
}