.uploader {
	max-height:0;
	transition: max-height 1s ease;
	overflow:hidden;
	.inner {
		padding:$grid-gutter-width/2 ($grid-gutter-width/2)-1;
		display:table;
		width:100%;
	}
	&.show, .dragover &, .requestids &, .uploading & {
		max-height:14em;
	}
	.new-receipts {
		margin:0 -0.3125em;
		list-style:none;
		padding:0;
		@include clearfix;
		li {
			float:left;
		}
		.files {
			padding:0;
			list-style:none;
			li {
				background:#fff;
				width:3.25em;
				height:2.3125em;
				position:relative;
				margin:0.3125em;
				border:0.3125em solid #fff;
				border-top-width:1.9375em;
				background:#dadada;
				&:after {
					content:'';
					position:absolute;
					right:-0.5625em;
					bottom:-0.3125em;
					width:0;
					height:0;
					border:0 solid transparent;
					border-width:2em 0.25em 0 0;
					border-top-color:#fff;
					opacity:0.5;
					transition: border-width 0.25s ease;
					z-index:2;
				}
				&:before {
					opacity:0;
					position:absolute;
					width:100%;
					left:0;
					right:0;
					bottom:0.5em;
					text-align:center;
					transform: scale(0);
					transition: all 0.25s ease;
				}
				progress {
					position: absolute;
					bottom: 0;
					left: 0;
					height: 0.3125em;
					width: 100%;
					z-index: 1;
				}
				&.hasalert {
					animation: evaporate 1s ease 2s forwards;
					&:before {
						opacity: 1;
						transform: scale(1);
					}
				}
				&.alert-success:before {
					//content: "\f00c";
					color:$brand-success;
				}
				&.alert-info:before {
					//content: "\f00c";
					color:$brand-info;
				}
				&.alert-warning:before {
					//content: "\f00c";
					color:$brand-warning;
				}
				&.alert-danger:before {
					//content: "\f12a";
					color:$brand-danger;
				}
			}
		}
	}
}

.new-receipts {
	margin:0;
}

.dropzone {
	background:rgba(0,0,0,0.1);
	border:1px solid transparent;
	line-height:1;
	width:100%;
	padding:0 1.25em;
	color:rgba(0,0,0,0.75);
	display:table-cell;
	vertical-align:middle;
	height:8em;
	transition: background 0.25s ease;
	.intro {
		text-align:center;
		p {
			font-size:1.3125em;
			& + p {
				font-size:1em;
			}
		}
		&:before {
			@extend %icon;
			@extend .icon-upload;
			font-size:4em;
		}
	}
	&:hover {
		cursor:pointer;
	}
	.intro.nodraganddrop {
		display:none;
	}
	html.nodraganddrop & {
		.intro.nodraganddrop {
			display:block;
		}
		.intro {
			display:none;
		}
	}
	.browse {
		text-decoration:underline;
	}
	&.dragover, .dragover & {
		background:rgba(0,0,0,0.2);
	}
	label {
		text-align:right;
		font-weight:normal;
	}
	&.requestids, .requestids & {
		animation: loading 1s ease infinite;
	}
	&.uploading, .uploading & {
		background:transparent;
		border:1px solid rgba(0,0,0,0.1);
		.intro {
			display:none;
		}
	}
}

progress {
	color: #60a926;
	background-color: #dadada;
	border: none;

	&:not(:indeterminate) {
		appearance: none;
	}

	&::-webkit-progress-bar {
		background-color: #dadada;
	}

	&::-webkit-progress-value {
		background-color: #60a926;
	}

	&::-moz-progress-bar {
		background-color: #60a926;
	}
}

progress:indeterminate,
.progress-indeterminate {
	background: none;
	-moz-appearance: progressbar;
	appearance: progressbar;
}
