.dropdown-menu {
	border-radius:0;
    margin:0;
	padding:0;
	box-shadow: rgba(0,0,0,0.05) 0 0.375em 0 0.25em;
	> li {
		padding:5px 0;
		> a {
			padding:5px 1em;
		}
		& + li {
			border-top:1px solid $dropdown-border;
		}
	}
	:focus {
		outline:none;
	}
    .tag.alt {
        font-size:14px;
        line-height:20px;
        width:20px;
        vertical-align:top;
    }
    &.clone {
    	display:block;
    }

	.glyphicon {
		margin-right: 0.5em;
	}
}

.table-responsive {
	.dropdown-toggle,
	.dropdown-menu {
		font-size: 0.875em;
		width: 100%;
	}

	.dropdown-menu {
		position: static;
		display: block;
		float: none;
		box-shadow: none;
		border: none;
		height: 0;
		overflow: hidden;

	}

    .dropdown-toggle:focus ~ .dropdown-menu,
    .dropdown-toggle:active ~ .dropdown-menu,
	.open .dropdown-menu {
		height: auto;
        z-index: $zindex-dropdown;
        position: relative;
        top: 1px;
	}
}
