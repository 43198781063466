/// Specific admin area styles

@media (min-width: $screen-md-min) {
    .admin form .btn-toolbar {
        background: darken(#f2f2f2, 3.5%);
        margin: 0;
        border-top: 1px solid $btn-default-border;
        position: fixed;
        z-index: 1;
        bottom: 0;
        left: (100% - $container-fluid) / 2;
        right: (100% - $container-fluid) / 2;
    }

    .btn-toolbar-group {
        float: right;
        padding: 0.5em;
    }
}
