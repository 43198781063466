.global-filter {
	select {
        &.btn {
            padding-right: $padding-base-vertical * 2;
        }

        &.btn-lg {
            padding-right: $padding-large-vertical * 2;
        }

        &.btn-sm {
            padding-right: $padding-small-vertical * 2;
        }

        &.btn-xs {
            padding-right: $padding-xs-vertical * 2;
        }

	    appearance: none;

	    background: #fff url('../icons-56px/arrow-down.svg') no-repeat right center;
	    background-image: url('../icons-56px/arrow-down.svg');

	    color:rgba(0,0,0,0.6);

	    background-size: 1.111em 0.556em;
	}
}
