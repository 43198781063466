h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top:0;
}

.lead {
    font-size:1.5em;
}

p strong {
    font-weight:normal;
    color:#000;
}

a.highlight {
    color:#0592ff;
}

.caret-left {
    border-bottom: $caret-width-base solid transparent;
    border-top: $caret-width-base solid transparent;
    border-right: $caret-width-base solid #000000;
    display: inline-block;
    height: 0;
    opacity: 0.3;
    vertical-align: baseline;
    width: 0;
}

main.container {
    position:relative;
    &:after {
        content:'';
        display:block;
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        border-right:1px solid #fff;
    }
}

nav.container {
    position:relative;
    z-index:1;
}

.input-group-addon {
    min-width:2em;
}

.input-group-btn {
    font-size: inherit;
}

.positive-value {
    color:#589a23;
}

.negative-value {
    color:#be2327;
}

@media (min-width: $screen-sm-min) {
    .pull-right-sm {
        float: right !important;
    }
    .pull-left-sm {
        float: left !important;
    }
}

@media (min-width: $screen-xm-min) {
    .pull-right-xm {
        float: right !important;
    }
    .pull-left-xm {
        float: left !important;
    }
}

@media (min-width: $screen-md-min) {
    .pull-right-md {
        float: right !important;
    }
    .pull-left-md {
        float: left !important;
    }
}

@media (min-width: $screen-lg-min) {
    .pull-right-lg {
        float: right !important;
    }
    .pull-left-lg {
        float: left !important;
    }
}

@media (min-width: $screen-xm-min) and (max-width: $screen-xm-max) {
  @include responsive-invisibility('.hidden-xm');
}