.paging {
	@include clearfix;
	clear:both;
	margin-bottom:20px;
	text-align:center;
	* {
		display:inline-block;
		vertical-align:middle;
	}
	a {
		text-decoration:none;
	}
	ol {
		padding:0;
		margin:0;
		list-style:none;
	}
	li {
		margin:0;
		> * {
			padding:($padding-base-vertical/$font-size-base)+em ($padding-base-horizontal/$font-size-base)+em;
            margin:($padding-base-vertical/$font-size-base/2)+em ($padding-base-horizontal/$font-size-base/2)+em;
            border:1px solid transparent;
            min-width:2em;
		}
	}
	a.to:active, li > *:active, li > *.active {
		outline: 0!important;
	    background-color:rgba(255,255,255,0.2);
	}
	.btn {
		float:none!important;
        margin:($padding-base-vertical/$font-size-base/2)+em ($padding-base-horizontal/$font-size-base/2)+em;
        padding:$padding-base-vertical $padding-base-horizontal;
	}
	@media (max-width: $screen-xs-max) {
		display:table;
		width:100%;
		> * {
			display:table-cell;
		}
		.back {
			text-align:left;
            .btn {
                margin-right:($padding-base-horizontal/$font-size-base)+em;
                margin-left:0;
            }
		}
		.forth {
			text-align:right;
            .btn {
                margin-left:($padding-base-horizontal/$font-size-base)+em;
                margin-right:0;
            }
		}
		ol {
			text-align: center;
		}
	}
}