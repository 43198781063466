@font-face {
    font-family: 'SourceSansPro';
    src: url('../fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('../fonts/Source_Sans_Pro/SourceSansPro-Semibold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon/icomoon.eot?p7z63z');
	src:url('../fonts/icomoon/icomoon.eot?#iefixp7z63z') format('embedded-opentype'),
		url('../fonts/icomoon/icomoon.woff?p7z63z') format('woff'),
		url('../fonts/icomoon/icomoon.ttf?p7z63z') format('truetype'),
		url('../fonts/icomoon/icomoon.svg?p7z63z#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

// Catchall baseclass
.icon,
%icon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tileview:before {
  content: "\e617";
}

.icon-plus:before {
  content: "\f067";
}

.icon-minus:before {
  content: "\f068";
}

.icon-waiting:before {
  content: "\f017";
}

.icon-check:before {
  content: "\f00c";
}

.icon-exclamation:before {
  content: "\f12a";
}

.icon-listview:before {
  content: "\e618";
}

.icon-export:before {
  content: "\e614";
}

.icon-remove:before {
  content: "\e610";
}

.icon-profile:before {
  content: "\e611";
}

.icon-cancel:before {
  content: "\e60d";
}

.icon-edit:before {
  content: "\e60e";
}

.icon-dropdown:before {
  content: "\e60c";
}

.icon-upload-2:before {
  content: "\e60a";
}

.icon-search:before {
  content: "\e608";
}

.icon-upload:before {
  content: "\e609";
}

.icon-last:before {
  content: "\e612";
}

.icon-first:before {
  content: "\e615";
}

.icon-forward:before {
  content: "\e616";
}

.icon-back:before {
  content: "\e60f";
}

.icon-income:before {
  content: "\e613";
}

.icon-accomodation:before {
  content: "\e604";
}

.icon-office:before {
  content: "\e600";
}

.icon-transport:before {
  content: "\e601";
}

.icon-telecom:before {
  content: "\e602";
}

.icon-advertising:before {
  content: "\e603";
}

.icon-purchase:before {
  content: "\e605";
}

.icon-food:before {
  content: "\e606";
}

.icon-administration:before {
  content: "\e607";
}

.icon-menu:before {
  content: "\e60b";
}