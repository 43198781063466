.notifications {
	> li {
		padding:0;
		> a {
			border-left:0.5em solid transparent;
			padding-top:10px;
			padding-bottom:10px;
			padding-left:0.5em;
			white-space:normal;
			min-width:20vw;
			&:hover, &:focus {
				background:inherit;
			}
		}
	}
	.warning {
		border-left-color:$brand-warning;
	}
	.danger {
		border-left-color:$brand-danger;
	}
	.info {
        border-left-color:$brand-info;
    }
	.success {
		border-left-color:$brand-success;
	}
}