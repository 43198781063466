.tile-receipt {
	#toggle-input-session{
		color: #00b3ee;
		text-decoration: underline;
		cursor: pointer;
	}

	h2 {
		font-weight:bold;
	}
	ul {
		list-style:none;
		padding:0;
	}
	.category {
		text-transform:uppercase;
		line-height:1.75em;
	}
    .categoryicon {
        font-size:1.75em;
        margin-right:0.25em;
        vertical-align:bottom;
    }
	.item {
		float:left;
		clear:left;
	}
	.price {
		float:right;
	}
	.item, .price, h2 {
		margin-bottom:0.5em;
		font-size:1.125em;
	}
	time {
		font-size:0.875em;
	}
	li {
		overflow:hidden;
		clear:both;
		.item, .price  {
			font-weight:bold;
		}
		p {
			margin:0;
		}
	}
	.summary {
		margin-bottom:1em;
		> li {
			border-bottom:1px solid rgba(0,0,0,0.25);
			padding:1em 0;
		}
		li li {
			.item, .price {
				font-weight:normal;
				margin-bottom:0;
			}
			.positive-value, .negative-value {
				font-weight: bold;
			}
		}
		.tags {
			padding:0.25em 0;
			ul {
				margin:0 -0.25em;
			}
			li {
				clear:none;
				float:left;
				margin:0.25em 0.25em;
			}
		}
	}

	.btn-toolbar{
		margin-bottom:2em;
	}
	.current {
		position:relative;
		background:rgba(0,0,0,0.05);
		padding: $grid-gutter-width - 10;
		text-align:center;
		img, .noimage {
			display:block;
            max-width:100%;
		}
		.noimage {
			background:#fff;
			display:table;
			width:25vw;
			height:25vw;
			p {
				display:table-cell;
				padding:1em;
				vertical-align: middle;
				text-align:center;
			}
		}
		a {
			display:inline-block;
			position:relative;
			max-width:100%;
		}
		.tag {
			position:absolute;
			left:0;
			top:0;
		}
	}
	.thumbs {
		* {
			display:block;
		}
		> * {
			margin-bottom:0.5em;
            position:relative;
		}
		img {
			margin:0 auto;
			max-width:100%;
		}
		@media (min-width: $screen-md-min) {
			a {
				border:5px solid transparent;
				padding:20px;
				transition: border-color .25s ease;
				&.active {
					border-color: rgba(0,0,0,0.05);
				}
			}
		}
	}
    .form-group {
        clear:both;
    }
    @media (max-width: $screen-xm-max) {
        [data-receiptgallery] {
            margin-top:20px;
        }
        .thumbs {
            > * {
                float:left;
                width:50%;
                padding:0 0.5em;
                height:50vh;
            }
            img {
                max-height:100%;
            }
        }
    }
	@media (max-width: $screen-xs-max) {
		.current {
			margin-bottom:0.5em;
		}
	}
}
