.tiles {
    position:relative;
    list-style:none;
    padding:0;
    margin:0;
    width:$container-md;
    @include clearfix;
    > li {
        float:left;
        height:0;
        padding:0 0 20% 0;
        position:relative;
        transition: z-index 0.5s ease;
        transform: perspective(2000);
        &.dragover {
            z-index:200;
        }

        &.img-tile {
            &:before {
                content: '';
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                background: white;
                height: 1px;
            }
        }
    }

    .tile {
        position:absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
        border-right:0;
        .titlebar {
            position:absolute;
            left:0;
            top:0;
            right:0;
        }
    }
    .flex {
        padding-bottom:0;
        height:auto;
        margin-bottom:1px;
        .tile {
            top:0;
            left:0;
        }
        .tile, .titlebar {
            position:relative;
            display:block;
        }
        .centerwrapper {
            display:block;
            width:auto;
            height:auto;
        }
    }
    .graph {
        .content {
            height:100%;
            padding:(13px/$font-size-large)+em 0;
            margin:0 (17px/$font-size-large)+em;
            &:after {
                border:2px solid #fff;
                content:"In ontwikkeling";
                position:absolute;
                width:50%;
                bottom:40%;
                left:25%;
                text-align:center;
                background:#ffcb05;
                color:#000;
                line-height:2em;
                transform: rotate(10deg);
            }
        }
        img {
            max-width:100%;
            position:absolute;
            bottom:(17px/$font-size-large)+em;
            opacity:0.5;
        }
    }
    @media (max-width: $screen-xs-max) {
        width:auto;
        padding:0;
        > li {
            padding-bottom:50%;
        }
        .tile {
            right:1px;
        }
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        width:auto;
        > li {
            padding-bottom:25%;
        }
    }
    @media (min-width: $screen-xm-min) and (max-width: $screen-xm-max) {
        width:$container-xm;
        > li {
            padding-bottom:25%;
        }
    }
    @media (min-width: $screen-lg-min) {
        width: $container-lg;
        > li {
            padding-bottom:12.5%;
        }
    }
}

.tile {
    border:1px solid #fff;
    border-top:none;
    transition: all 0.5s ease;
    box-shadow: none;
    padding:0;
    margin:0 auto;
    float:none;
    [data-dropzone].dragover & {
        transform: scale(1.1);
        outline:none;
        z-index:5;
        box-shadow: rgba(0,0,0,0.05) 0 0.75em 0 0.5em;
        .number {
            color:transparent;
            &:after {
                opacity:1;
                transform: scale(1);
            }
        }
        .content {
            > .glyphicon, > .icon {
                &:before {
                    opacity:0;
                    transform: scale(0);
                }
                &:after {
                    opacity:1;
                    transform: scale(1);
                }
                &+.number {
                    color:#000;
                }
            }
        }
    }
    .titlebar {
        @include clearfix;
        display:block;
        margin:0;
        text-transform:uppercase;
        border:none;
        min-height:3.125em;
        &+.centerwrapper {
            padding-top:1.25em;
        }
        .btn {
            border:none;
            background:none;
            color:rgba(0,0,0,0.6);
        }
        .btn-lg {
            padding:(13px/$font-size-large)+em (17px/$font-size-large)+em;
        }
        select {
            text-transform:uppercase;
        }
    }
    .title {
        border:none;
        font-size:($font-size-large/$font-size-base)+em;
        line-height:$line-height-large;
        padding:(13px/$font-size-large)+em (17px/$font-size-large)+em ;
        margin:0;
        display:block;
        color:rgba(0,0,0,0.6);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight:normal;
        max-width:100%;
        .tag {
            font-size:1em;
            margin:-0.5em 0.5em -0.5em 0;
        }
    }
    * + .title {
        padding-left:0;
        max-width:45%;
    }
    .content {
        display:block;
        padding:$grid-gutter-width/2 ($grid-gutter-width/2)-1;
        position:relative;
        @include clearfix;
        &.widepaddingv {
            padding-top:$grid-gutter-width;
            padding-bottom:$grid-gutter-width;
        }
        &.widepadding {
            padding:$grid-gutter-width ($grid-gutter-width*1.5)-1;
            @media (max-width: $screen-sm-max) {
                padding:$grid-gutter-width/2;
            }
        }
    }
    .centerwrapper {
        display:table;
        width:100%;
        height:100%;
        .content {
            display:table-cell;
            vertical-align:middle;
            text-align:center;
            padding:0;
            text-transform:uppercase;
        }
    }
    .number {
        font-size:2.25em;
        display:block;
        color:inherit;
        transition: color 0.25s ease;
        [data-dropzone] & {
            &:after {
                opacity:0;
                @extend %icon;
                @extend .icon-upload:before;
                position:absolute;
                top:50%;
                right:0;
                left:0;
                text-align:center;
                font-size:1em;
                color:#000;
                margin-top:-0.5em;
                transform: scale(0);
                transition: all 0.25s ease;
            }
        }
    }
    .content {
        > .icon {
            display:block;
            font-size:3.5em;
            margin:0;
            position:relative;
            &+.number {
                font-size:1.875em;
                color:#000;
                margin:0.5em 0 -0.5em 0;
            }
            [data-dropzone] & {
                &:after {
                    opacity:0;
                    @extend .icon-upload:before;
                    position:absolute;
                    top:0;
                    right:0;
                    left:0;
                    text-align:center;
                    font-size:1em;
                    color:#000;
                    transform: scale(0);
                }
                &:before {
                    transform: scale(1);
                }
                &:before, &:after {
                    transition: all 0.25s ease;
                }
                &+.number:after {
                    display:none;
                }
            }
        }
    }
    @media (max-width: $screen-xs-max) {
        font-size:4.375vw;
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        font-size:2.29vw;
    }
    @media (max-width: $screen-xm-max) {
        .titlebar {
            &+.centerwrapper {
                padding-top:2.5em;
            }
        }
        .content {
            > .icon {
                &+.number {
                    margin:0;
                }
            }
        }
    }
}
a.tile {
    &, &:hover, &:active, &:focus {
        text-decoration:none;
    }
}

@include tile-variant('.tile', #f2f2f2, #757575, #757575);
@include tile-variant('.tile-alt', #e5e5e5, #757575, #757575);
@include tile-variant('.tile-negative', #cd262a, #fff, #757575);
@include tile-variant('.tile-positive', #60a926, #fff, #757575);
@include tile-variant('.tile-tax', #7b86c6, #fff, #757575);
@include tile-variant('.tile-focus', #ffcb05, #000, #757575);
