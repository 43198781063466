.btn-group {
  .btn + .btn,
  .btn + .btn-group,
  .btn-group + .btn,
  .btn-group + .btn-group {
    margin-left: 0;
  }
}

.btn-toolbar-group {
    .btn {
        display: block;
        width: 100%;
        margin: 0 0 0.25em 0;
    }

    @media (min-width: $screen-sm-min) {
        .btn {
            display: inline-block;
            width: auto;
            margin-bottom: 0;
        }
    }
}
