.bottombar {
	bottom:0;
	left:0;
	right:0;
	position:fixed;
	background:#fff;
	max-height:25%;
	z-index:2000;
    overflow:auto;
	.container {
		padding:0 $grid-gutter-width/4;
	}
	&:before {
		content:'';
		background:rgba(0,0,0,0.05);
		max-height:0.375em;
		height:100%;
		position:absolute;
		left:0;
		right:0;
		bottom:100%;
	}
}