//
// Buttons
// --------------------------------------------------


// Base styles
// --------------------------------------------------

.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: $btn-font-weight;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  @include button-size($padding-base-vertical, $padding-base-horizontal*2, $font-size-base, $line-height-base, $border-radius-base);
  user-select: none;

  &,
  &:active,
  &.active {
    &:focus {
      @include tab-focus();
    }
  }

  &:hover,
  &:focus {
    color: $btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
    pointer-events: none; // Future-proof disabling of clicks
    opacity: .65;
    box-shadow: none;
  }
  .icon {
      margin:-0.35em 0 0 0;
      font-size:1.111em;
      vertical-align:middle;
  }
  .icon-upload, .icon-back, .icon-forward, .icon-first, .icon-last {
      margin-top:-0.25em;
      font-size:1.333em;
  }
  .open &, &:active {
      box-shadow: none;
  }
  > * {
      font-size:1em;
  }
  &.btn-menu {
      @extend %icon;
      @extend .icon-menu;
      font-size:1.875em;
      line-height:1.667em!important;
      height:1.667em;
      padding-top:0;
      padding-bottom:0;
      top:0!important;
  }
  &[data-toggle] {
      .icon-dropdown {
          font-size:0.556em;
          line-height:1.5em;
          vertical-align:middle;
      }
  }
}


// Alternate buttons
// --------------------------------------------------

.btn-default {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}
.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

.btn-secondary {
    @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}

.btn-primary-yellow {
  @include button-variant($btn-primary-yellow-color, $btn-primary-yellow-bg, $btn-primary-yellow-border);
}

// Success appears as green
.btn-success {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}
// Info appears as blue-green
.btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}
// Warning appears as orange
.btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}
// Danger and error appear as red
.btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

.btn-yellow{
  @include button-variant(black, #FFCB05, #E1B000);
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: $link-color;
  font-weight: normal;
  cursor: pointer;
  border-radius: 0;

  &,
  &:active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    box-shadow: none;
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: underline;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
  &.highlight {
    color:#0592ff;
  }
}


// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($padding-large-vertical, $padding-large-horizontal*2, $font-size-large, $line-height-large, $border-radius-large);
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size($padding-small-vertical, $padding-small-horizontal*2, $font-size-small, $line-height-small, $border-radius-small);
}
.btn-xs {
  @include button-size($padding-xs-vertical, $padding-xs-horizontal*2, $font-size-small, $line-height-small, $border-radius-small);
}


// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

.btn-hclear {
  border-left:none;
  border-right:0;
  padding-left:0;
  padding-right:0;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
