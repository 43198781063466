.tag {
	// position:absolute;
	position:relative;
	font-size:0.875em;
	// left:0;
	// bottom:0;
	// top:auto;
	color: $body-bg;
	z-index:0;
	text-align:left;
	padding:0 0 0.25em 0.25em;
	margin:0;
	text-align:center;
	width:1.5em;
	//@extend .icon-exclamation;
	&:after {
		content:'';
		position:absolute;
		border:3em solid transparent;
		border-right:none;
		border-bottom-width:0;
		border-left-color:#a5a5a5;
		left:0;
		bottom:0;
		z-index:-1;
		width:0;
		height:0;
	}
	&.warning:after {
		border-left-color:$alert-warning-bg;
	}
	&.danger:after {
		border-left-color:$alert-danger-bg;
	}
	.icon {
		top:auto;
	}
	&.info {
        //@extend .icon-scanning;
        &:after {
            border-left-color:$alert-info-bg;
        }
    }
	&.success {
		//@extend .icon-check;
		&:after {
			border-left-color:$alert-success-bg;
		}
	}
	&.top {
		// bottom:auto;
		// top:0;
		padding:0.25em 0 0 0.25em;
		&:after {
			border-top:none;
			border-bottom-width:2.5em;
			bottom:auto;
			top:0;
		}
	}
	&.inline {
		background-color:#a5a5a5;
		border:1px solid transparent;
		padding:0.5em 0.75em;
		width:auto;
		line-height:1.167em;
		font-size:0.75em;
		.icon {
			vertical-align:bottom;
			font-size:1.167em;
		}
		&:after {
			display:none;
		}
		&.warning {
			background-color:$alert-warning-bg;
			border-color:$alert-warning-border;
		}
		&.danger {
			background-color:$alert-danger-bg;
			border-color:$alert-danger-border;
		}
		&.info {
			background-color:$alert-info-bg;
			border-color:$alert-info-border;
		}
		&.success {
			background-color:$alert-success-bg;
			border-color:$alert-success-border;
		}
	}

	&.alt {
        @extend %bg-hexagon;
		border: none;
        color: $text-color;
		position: static;
		line-height: 2em;
		width: 2em;
		display: inline-block;
		text-align: center;
		padding: 0;

		&:after {
			display: none;
		}

		&.warning {
            color: $body-bg;
			@extend %bg-hexagon-warning;
		}

		&.danger {
            color: $body-bg;
			@extend %bg-hexagon-danger;
		}

		&.info {
            color: $body-bg;
			@extend %bg-hexagon-info;
		}

		&.success {
            color: $body-bg;
			@extend %bg-hexagon-success;
		}
	}

    &:focus {
        outline:none;
        + .taginfo {
            display:block;
        }
    }
}

.taginfo {
    color: $body-bg;
    display:none;
    background-color:#a5a5a5;
    z-index:10;
    padding:0.5em 0.5em 0.5em 2em;
    font-size:0.875em;
    list-style:disc;
    margin:0;
    position:absolute;
    width:20em;
    max-width:100vw;
    &.warning {
        background-color:$alert-warning-bg;
    }
    &.danger {
        background-color:$alert-danger-bg;
    }
    &.info {
        background-color:$alert-info-bg;
    }
    &.success {
        background-color:$alert-success-bg;
    }
}
