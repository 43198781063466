@keyframes evaporate {
	0% {
		opacity:1;
		filter: blur(0);
	}
	100% {
		opacity:0;
		transform: scale(1.1);
		filter: blur(1em);
	}
}

@keyframes loading {
	0% {
		opacity:1;
	}
	50% {
		opacity:0.5;
	}
	100% {
		opacity:1;
	}
}

@keyframes loadingflap {
	0% {
		border-width:6em 0.75em 0 0;
	}
	50% {
		border-width:5em 1em 0 0;
	}
	100% {
		border-width:6em 0.75em 0 0;
	}
}

@keyframes avatar {
	0% {
		clip-path: polygon(0 50%, 25% 6.667%, 75% 6.667%, 100% 50%, 75% 93.333%, 25% 93.333%);
	}
	100% {
		clip-path: polygon(25% 6.667%, 75% 6.667%, 100% 50%, 75% 93.333%, 25% 93.333%, 0 50%);
	}
}

@keyframes bgoverlayin {
	0% {
		background-color:rgba(0,0,0,0);
	}
	100% {
		background-color:rgba(0,0,0,0.5);
	}
}

@keyframes tilein {
	0% {
		transform: rotateX(45deg) scale(0.5);
		opacity:0;
	}
	100% {
		transform: rotateX(0deg) scale(1);
		opacity:1;
	}
}
