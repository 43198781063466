.table-receipts {
    .no-results {
        text-align:center;
    }

	.status,
    .category,
    .actions {
		padding-top: 7px;
		padding-bottom: 7px;
		text-align: center;
		vertical-align: top;
	}

    .status,
    .category {
		width: 3em;

		* {
			// text-align:left;
		}
    }

    .category > * {
		font-size:1.75em;
	}

    .actions {
		text-align: right;
	}

    .tax,
    .taxdeductible,
    .total,
    .date {
		width:8em;
	}

	.taxdeductible {
		font-weight: bold;
	}

	th,
    td {
		white-space:nowrap;
	}

	tbody th {
		font-weight:normal;
	}

	.loading & {
		animation: loading 1s ease infinite;
	}
}
